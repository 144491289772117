<template>
  <div class="about">
    <vue-background-image class="hero-header subpage" :image-source="require('@/assets/pen.jpg')">
      <div class="hero-header-content max-width">
        <h1 class="uppercase">
          EXPLORE THE RANGE<br>
          OF SERVICES
        </h1>
      </div>
      <div class="max-width hero-header-page-title">
        <div class="hero-page-title-inner">
          <h3 class="hero-header-title">About Clarity</h3>
          <div class="chevron"><div class="chevron-inner"><img :src="require('@/assets/chevron.svg')" /></div></div>
        </div>
      </div>
    </vue-background-image>
    <vue-background-image class="bottom-text-image" :image-source="require('@/assets/hero.jpg')">
      <section>
        <div class="about-text max-width">
          <p>
            Founded in 2018, Clarity provides professional and leadership development services to a portfolio of clientele
            in a wide range of industries spanning the corporate, non-profit, and academic sectors. Sally utilizes resonate
            leadership principals to build connections, grow trust, and motivate clients to push beyond emotional constraints
            to achieve their career goals. Working with Clarity will set the groundwork for new levels of professional
            effectiveness by utilizing industry tools and best practices to explore self-awareness, strengths, values, and desires.
          </p>
        </div>
      </section>
      <div class="offers max-width">
      <section>
        <h4 class="underline">Executive Coaching</h4>
        <p>
          High Potential Coaching<br>
          Career Coaching<br>
          Leadership & Career Assessments</p>
      </section>
      <section>
        <h4 class="underline">Leadership Development</h4>
        <p>
          Leadership Training<br>
          Career Development<br>
          C-Suite Readiness
        </p>
      </section>
      <section>
        <h4 class="underline">Organizational Consulting</h4>
        <p>
          Talent Management<br>
          Non-Profit & Small Business Strategy<br>
          Organization Development
        </p>
      </section>
    </div>
    </vue-background-image>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style lang="scss" scoped>
.about-text {
  font-size: 1.5em;
  font-weight: 100;
}

@media(min-width: $bp-l) {
  .about /deep/ .vue-background-image.hero-header div {
    background-size: 125% !important;
    background-position: center 25% !important;
  }
}

.offers {
  display: grid;
  grid-gap: 1em;
  text-align: center;

  section {
    padding: 1.75em;

    h4 {
      text-transform: uppercase;

      &:after {
        background-color: $white;
      }
    }

    background: rgba($green, 0.5);
  }

  @media(min-width: $bp-s) {
    grid-gap: 2em;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media(min-width: $bp-m) {
    section {
      padding: 2.5em;
    }
  }
}
</style>
