<template>
  <div class="benefits">
    <vue-background-image class="hero-header subpage" :image-source="require('@/assets/coffee.jpg')">
      <div class="hero-header-content max-width">
        <h1 class="uppercase">
          EVERY JOURNEY BEGINS<br>
          WITH A FIRST STEP
        </h1>
      </div>
      <div class="max-width hero-header-page-title">
        <div class="hero-page-title-inner">
          <h3 class="hero-header-title">Discover</h3>
          <div class="chevron"><div class="chevron-inner"><img :src="require('@/assets/chevron.svg')" /></div></div>
        </div>
      </div>
    </vue-background-image>
    <vue-background-image class="bottom-text-image" :image-source="require('@/assets/hero.jpg')">
      <div class="max-width row row-bp-s-up-third">
        <section>
          <h4 class="underline">FOCUS YOUR VISION</h4>
          <p>
            Clarity is at the heart of every new step forward. Whether a professional goal or a personal desire,
            clarity of purpose sets you on the right path to success. Getting sharper on your goals can certainly
            be a challenge but having someone to walk along with you on the journey can move you more quickly down the right path.
          </p>
          <p>
            Coaching can provide you the support needed to make a clear decision for yourself. Sally is a certified, trained
            coach who believes that the client’s goals are at the heart of every coaching conversation. As a trained Gestalt
            coach and leadership development professional, Sally will work with you to take your leadership or personal goals to the next level.
          </p>
        </section>
        <section>
          <h4 class="underline">DISCOVER YOUR POTENTIAL</h4>
          <p>
            Many of us struggle to clearly see and articulate our own strengths. Coaching provides that clarity.
            Through a coaching engagement you will experience future-focused conversations in which Sally will help focus
            your vision through a nuanced approach of powerful questions, deep listening, and active summarization.
            Developing your self-awareness, Sally will support you in building personal accountability to the things that are most
            important to you.
          <p>
          <p>
            Sally will be your biggest cheerleader as well as your biggest challenger; she’ll listen to you for
            what you say but also for what you don’t say. The key to clarity is seeing your personal best from within.
            Sally believes that by fully embracing your own strengths and identifying the things that are holding you back,
            will lead you to discovering your untapped potential.
          </p>
        </section>
        <section>
          <h4 class="underline">UNLOCK YOUR FUTURE</h4>
          <p>
            Clearing your vision, quieting the noise that surrounds you,
            and focusing on your personal strengths will allow the most important things to come into focus for you.
            Whether its leadership or personal development, Sally has a passion for helping others achieve success.
            We live in a world that needs great leaders and passionate visionaries at every level of society.
          </p>
          <p>
            Sally will help you achieve your personal best. With over 25 years working for some of the most well-known
            consumer goods' companies, Sally knows leadership from a firsthand experience. So, whether your continuing to develop your career,
            looking for career transition, or are looking for what comes next, Sally can support you in your next steps.
            With Sally you’ll experience a well-grounded, warm, and engaging coach who understands and can help you enhance your vision with Clarity.
          </p>
        </section>
      </div>
    </vue-background-image>
  </div>
</template>

<script>
export default {
  name: 'Discover'
}
</script>

<style lang="scss" scoped>
.row {
  grid-column-gap: 2.5em;
}

@media(min-width: $bp-l) {
  .benefits /deep/ .vue-background-image.hero-header div {
    background-size: 125% !important;
    background-position: 0% center !important;
  }
}
</style>
