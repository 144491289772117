<template>
  <div class="about-app max-width text-center">
    <img alt="Clarity logo" src="../assets/logo.svg">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'AboutApp',
  components: {
    HelloWorld
  }
}
</script>
