<template>
  <div class="connect">
    <vue-background-image class="hero-header subpage" :image-source="require('@/assets/hero.jpg')" key="1">
      <div class="hero-header-content max-width">
        <h1 class="uppercase">
          ENGAGE A COACH WITH<br>
          FIRST HAND EXPERIENCE
        </h1>
      </div>
      <div class="max-width hero-header-page-title">
        <div class="hero-page-title-inner">
          <h3 class="hero-header-title">Clarity Coach</h3>
          <div class="chevron"><div class="chevron-inner"><img :src="require('@/assets/chevron.svg')" /></div></div>
        </div>
      </div>
    </vue-background-image>
    <vue-background-image class="bottom-text-image" :image-source="require('@/assets/hero.jpg')" key="2">
      <section>
        <div class="max-width row row-bp-m-up-half">
          <div>
            <h4 class="underline owner-name">SALLY A. DAY - Executive Coach</h4>
            <vue-image class="headshot show-tablet" alt="Sally A. Day Headshot" :width="768" :height="666" :source="require('../assets/headshot.jpg')"></vue-image>
            <p>
              Sally comes to executive coaching with more than 25 years of experience in Procurement and Supply Chain Management.
              She has worked for many of the industry’s most well-known consumer food companies, including The J. M. Smucker Company, Unilever, Keebler, and Nestle.
              Building a successful career in a number of Fortune 500 companies, Sally worked in positions of increasing responsibility leading complex organizations.
              Sally’s experience also includes working as a Director of Leadership & Organization Development as an outgrowth of her strengths and interests in strategy and leadership.
            </p>
            <img class="logo-strip" alt="Logo Strip" src="../assets/logo-strip.jpg" />
            <p>
              Sally knows firsthand the kinds of challenges leaders’ encounter.
              Whether you’ve taken on a new role, are working to develop your leadership capabilities, or you’re looking for what comes next, Sally will help you discover your untapped potential.
              She is a versatile, high-energy executive coach. She has a warm and engaging style with an approach of putting her clients first.
              Sally considers herself a student of resonant leadership with a thirst for life-long learning and supporter of the personal and professional growth of others.
              She has a career’s worth experience in coaching and mentoring; and her client base has included c-suite executives and high potential leaders
            </p>
            <p>
              Sally received her Certificate in Executive Coaching from Weatherhead School of Management, Case Western Reserve University and is certified to facilitate instruments such as the Emotional & Social Competency Inventory (ESCI) and the Hogan Assessment.
              She completed her Gestalt Professional Certified Coach (GPCC) certification at the prestigious Gestalt Institute of Cleveland, an accredited International Coach Federation organization.
              Her additional certifications include Board Certified Coach (BCC) through the Center for Credentialing and Education and is a Professional Certified Coach (PCC) with the International Coaching Federation.
            </p>
            <p>
              Sally earned an Executive MBA from Kent State University and completed her B.S. in Business Management and International Business, with a minor in Polish Language from Alliance College.
              She lives in NE Ohio with her husband, Richard and two children, Emily & Nate.
            </p>
          </div>
          <div>
            <vue-image class="headshot hide-tablet" alt="Sally A. Day Headshot" :width="768" :height="666" :source="require('../assets/headshot.jpg')"></vue-image>
            <h4 class="inquire">
              TO CONNECT:
            </h4>
            <form id="ct-form" action="//formspree.io/coach@claritysallyday.com" method="POST" @submit="setLoading()">
              <input id="ct-name" type="text" name="name" placeholder="Name" required="">
              <input id="ct-email" type="email" name="_replyto" placeholder="Email" required="">
              <input id="ct-subject" name="_subject" type="text" placeholder="Subject" required="">
              <textarea id="ct-message" rows="3" placeholder="Leave a Message" name="message" required=""></textarea>
              <input type="text" name="_gotcha" style="display: none">
              <button class="button" type="submit"  :class="{ 'loading': loading }">Contact Us</button>
            </form>
          </div>
        </div>
      </section>
    </vue-background-image>
  </div>
</template>

<script>
export default {
  name: 'Connect',

  data () {
    return {
      loading: false
    }
  },

  methods: {
    setLoading () {
      this.loading = true
    }
  }
}
</script>

<style lang="scss" scoped>
.connect {
  input, textarea, button {
    width: 100%;
    display: block;
    margin-bottom: 1em;
  }

  /deep/ .vue-background-image.hero-header {
    position: relative;

    > div {
      background-size: cover !important;
      background-position: center !important;
    }
  }

  @media(min-width: $bp-m) {
    p {
      font-size: 1.1em;
    }
  }

  h4 {
    font-weight: 500;
  }
}

.owner-name {
  display: inline-block;
}

.headshot {
  width: 100%;
  margin-bottom: 2em;

  @media(min-width: $bp-s) {
    max-width: 500px;
    width: 40vw;
  }
}

.logo-strip {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 1em;
  margin-bottom: 1em;
}

.inquire {
  margin-top: 1em;
  margin-bottom: 1em;

  @media(min-width: $bp-s) {
    margin: 0.25em 0 1em;
  }
}

.row {
  display: block;
  grid-template-columns: 1.5fr 1fr;
  grid-column-gap: 2.5em;

  @media(min-width: $bp-s) {
    display: grid;
  }
}
</style>
